.contentNavigation {
  width: fit-content;
  height: fit-content;

  grid-column: 2;

  align-self: center;

  button {
    height: 2rem;
    width: 2rem;

    cursor: pointer;

    border: none;
    border-radius: .75rem;
    box-shadow: 0 0.1rem 10px rgba(0, 0, 0, 0.301);

    background-color: var(--background-white);

    font-weight: bold;
    font-size: 1rem;

    transition: background-color .5s;

    & + button {
      margin-top: 0.5rem;
    }

    &:hover {
      filter: brightness(0.9);
    }

    &.activePage {
      background-color: var(--red-300);

      &:hover {
        filter: brightness(1);
      }
    }
  }
}

@media (max-width: 1024px) {
  .contentNavigation {
    grid-row: 2; 
    grid-column: 1; 
    
    justify-self: center;

    button + button {
      margin-top: 0; 
      margin-left: .5rem;
    }
  }
}