.container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  z-index: 1;

  position: fixed;

  animation: opacity-in 2s ease forwards;

  &.transition {
    animation: opacity-out 1s ease forwards;
  }
  
  .backgroundImg {
    z-index: -1;
    opacity: 0;
    filter: contrast(70%), brightness(50%);
    transform: scale(1.5);
    position: absolute;
    top: -10rem;

    transition: all 2s;

    object-fit: cover;
  }

  
  .wrapper {
    max-width: 1120px;
    margin: 1rem auto 0;
    
    font-size: 2rem;

    .dedicatoria {
      text-align: end; 
      padding-right: 2.5rem;
      color: var(--red-300);
    }
    
    .end {
      text-align: end;
    }

    .content {
      text-align: justify;
      margin-top: 1rem;
      padding: 0 2rem 3rem;

      height: calc(100vh - 10.75rem);

      display: flex;
      flex-direction: column;

      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.404); 
        border-radius: 8px;
      }

      scrollbar-color: rgba(0, 0, 0, 0.404) transparent;
      scrollbar-width: 10px;
    

      p {
        margin-top: 2rem;
      }
    }
  }
}

@keyframes opacity-in {
  from{
    opacity: 0; 
  }
  to {
    opacity: 1;
  }
}

@keyframes opacity-out {
  from { 
    opacity: 1; 
  }
  to {
    opacity: 0;
  }
}