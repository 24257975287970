.content {
  min-height: 100%; 

  padding: 0rem 3rem 1rem 5rem;

  display: none;
  visibility: hidden;

  grid-template-rows: 3rem repeat(6, 6rem);
  gap: 1rem;

  h2 {
    text-align: center;
  }

  &.activePage {
    display: grid;

    visibility: visible;

    .card {
      animation: card-animation 1s forwards; 
    }
  }

  .card {
    height: fit-content;
    width: fit-content;
    max-width: 20rem;
    
    align-self: center;
    display: flex;

    background-color: transparent;

    grid-column: 1;

    cursor: pointer;

    color: var(--red-300);

    transition: filter 0.5s;

    transform: translateX(-25%); 
    opacity: 0;

    &:hover {
      filter: brightness(0.9);
    }

    h3 {
      font-weight: bolder;
      font-size: 1.65rem;
      display: flex;
      align-items: center;
    }
  }

  .cardEven {
    justify-self: end;
    transform: translateX(25%);
  }
}

@keyframes card-animation {
  to {
    transform: translateX(0); 
    opacity: 1; 
  }
}

@media (max-width: 1023px) {
  .content {
    padding: 0 ;

    .card, .cardEven {
      justify-self: center;
    }
  }
}