.wrapper {
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  opacity: 0;
  animation: opacity-in 2s ease forwards; 

  &.transition {
    animation: opacity-out 1s ease forwards; 
  }

  .backgroundImg {
    height: 80vh;

    margin-top: 2rem; 
  
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  
    cursor: pointer;
    transition: filter 1s, transform 1s; 
  
    animation: blur-in 2s ease;
  
    &.backgroundImgActive {
      position: absolute;
  
      animation: blur-out 1s ease forwards;
  
      top: 6rem; 
      z-index: -1;
      cursor: normal;
    }
  }  
}

.content {
  max-height: calc(100% - 6rem);
  min-height: calc(100vh - 6rem);
  max-width: 100vw; 

  position: relative;

  height: 80vh; 
  width: 100vw; 

  .card {
    position: absolute;

    border: none; 
    background-color: transparent;
    cursor: pointer;

    h2 {
      font-size: 2.25rem;
      color: var(--red-300);

      background-color: transparent;

      transition: all .3s; 
      &:hover {
        filter: brightness(0.9);
      }
    }

    &.card1 {
      top: 2.5%; left: 50%;
      transform: translateX(-50%);
      animation: opacity-in 3s ease forwards;
    }
    &.card2 {
      top: 17%; left: 20%; 
      animation: opacity-in 3s ease forwards;
    }
  
    &.card3 {
      top: 22.76%; right: 17%;
      animation: opacity-in 3s ease forwards;
    }
  
    &.card4 { 
      top: 39.8%; left: 9%;  
      animation: opacity-in 3s ease forwards;
    }
  
    &.card5 {
      top: 55%; right: 8%; 
      animation: opacity-in 3s ease forwards;
    }
  
    &.card6 {
      top: 67%; left: 20%; 
      animation: opacity-in 3s ease forwards;
    }
  
    &.card7 {
      top: 79%; right: 13%; 
      animation: opacity-in 3s ease forwards;
    }

    &.card8 {
      bottom: 5%; 
      left: 40%;
      animation: opacity-in 3s ease forwards;
    }
  }
  
}

@keyframes opacity-in {
  from{
    opacity: 0; 
  }
  to {
    opacity: 1;
  }
}

@keyframes opacity-out {
  from { 
    opacity: 1; 
  }
  to {
    opacity: 0;
  }
}

@keyframes blur-in {
  from {
    filter: blur(5rem)
  }
  to {
    filter: blur(0); 
  }
}

@keyframes blur-out {
  from {
    filter: blur(0);
  }
  to {
    filter: blur(1.5rem); 
  }
}

@media (max-width: 1024px) {
  .content {
    .card {
      h2 {
        color: var(--red-800);
      }

      &.card2 {
        left: 13%;
      }
      &.card3 {
        right: 10%;
      }
    }
  }
}

@media (max-width: 768px) {
  .wrapper {
    overflow-y: auto;
  }
  .content {
    padding: 0 6rem; 
    display: flex; 
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around; 

    .cardEven {
      align-self: flex-end;
    }

    .card {
      position: unset;
      
      &.card1 {
        transform: translateX(0);
      }
    }
  }
}

@media (max-width: 650px) {
  .wrapper{

    .backgroundImg {
      position: absolute;
      top: 50vh; 
      transform: translateY(-50%);
      max-height: 100vw;
  
      &.backgroundImgActive {
        
        top: 50vh; 
        transform: translateY(-50%);
      }
    }
  }
}

@media (max-width: 450px) {
  .content {
    padding: 0 2rem;
  }
}

