.modalOverlay {
  position: fixed;
  top: 0;

  height: 100vh; 
  width: 100vw;

  background-color: rgba(0, 0, 0, 0.2);

  z-index: 99;
  
  cursor: pointer;
  
  transition: all 1s; 

  opacity: 0;
  visibility: hidden;

  &.modalActive {
    visibility: visible;
    opacity: 1;

    .modalContent {
      width: 25%;
    }
  }

  .modalContent {
    width: 0; 
    height: 100%;
    
    position: absolute;
    right: 0;

    background-color: var(--background-white);
    
    padding: 2rem 1rem; 
    border-radius: 2rem 0 0 2rem;

    white-space: nowrap;
    
    cursor: default;

    z-index: 999;

    transition: width 1s; 

    overflow-y: auto; 
    
    h2 {
      margin-bottom: 2rem;

      flex: 1;

      text-align: center;
      font-size: 1.75em; 
    }
    
    ul {
      list-style: none;
      
      border-bottom: 1px solid black;
  
      li {
        padding: .5rem 0;
        border-top: 1px solid black; 
      }

      ul {
        margin-top: 0rem; 
        margin-left: 2rem; 

        border: none;

        li {
          margin: 0;

          border: none;
        }
      }

    }

    button {
      border: none; 
      background-color: var(--background-white);

      font-size: 1.5rem;

      padding: 0 .5rem .25rem; 
      border-radius: 1.25rem;

      cursor: pointer;

      transition: all .3s; 

      &:hover {
        color: var(--red-500);
        filter: brightness(0.9) 
      }
    }

    .modalClose {
      height: 2rem; 
      width: 2rem; 

      position: absolute;
      top: .5rem; right: 1rem;

      cursor: pointer;

      background-color: var(--background-white);
      
      border-radius: 1rem;

      transition: all .3s;

      &:hover {
        filter: brightness(0.9);
        color: var(--red-300);
      }
    }
  }
}

@media (max-width: 768px) {
  .modalOverlay {
    &.modalActive {

      .modalContent {
        width: 50%;
      }
    }
  }
}

@media (max-width: 500px) {
  .modalOverlay {
    &.modalActive {

      .modalContent {
        width: 100%;
        border-radius: 0;
      }
    }
  }
}