.container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;

  animation: opacity-in 2s ease;

  &.transition {
    animation: opacity-out 1s ease forwards;
  }
}

.backgroundImg {
  min-height: 100vh;
  min-width: 100vw;
  z-index: -1;

  opacity: 0;
  transform: scale(1);
  filter: contrast(70%), brightness(50%);
  position: absolute;

  transition: opacity 1s;

  object-fit: cover;
}

.wrapper {
  max-width: 1600px;
  max-height: calc(100vh - 6rem);

  margin: 0 auto;
  padding: 3rem 2rem 0;

  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: transparent;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.404);
    border-radius: 8px;
  }

  scrollbar-color: rgba(0, 0, 0, 0.404) transparent;
  scrollbar-width: 10px;

  .about {
    padding-right: 2rem;

    .aboutHeading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        background-color: transparent;
        border: none;
        cursor: pointer;

        position: relative;

        &::before {
          content: "Baixar Pdf";
          color: var(--red-300);
          background-color: var(--background-white);

          position: absolute;
          top: -2.125rem;
          left: -75%;

          font-size: 1.125rem;
          font-weight: bold;

          padding: 0.25rem 0.5rem;
          border-radius: 1rem;

          width: fit-content;
          white-space: nowrap;

          opacity: 0;

          transition: opacity .3s; 
        }

        &:hover::before {
          opacity: 1;
        }

        svg {
          color: var(--red-300);
        }
      }
    }

    h2 {
      font-size: 3rem;
      margin-bottom: 2rem;
      color: var(--red-300);
    }

    p {
      font-size: 1.65rem;
      text-align: justify;
      text-indent: 1rem;

      &.smaller {
        text-indent: 0;
        line-height: 1.75rem;
      }

      &.end {
        text-align: end;
      }
    }

    p + p {
      margin-top: 1rem;
    }
  }

  .contentWrapper {
    display: grid;
    grid-template-columns: 1fr 2rem;
    padding: 1rem 2rem;

    &.pdfContainer {
      padding: 0;
      display: block;
      height: 100%;

      animation: opacity-in 2s ease forwards;
    }

    .imgContainer {
      margin-top: 1rem;
      position: relative;
      width: fit-content;
      height: fit-content;

      opacity: 0;
      transition: opacity 1s;

      img {
        border-radius: 1rem;
        max-width: 100%;
      }

      button {
        width: 3rem;
        height: 3rem;

        background: transparent;
        border: none;

        position: absolute;
        cursor: pointer;

        color: var(--red-300);
        transition: all 0.3s;

        transform: translate(-50%, -50%);

        &.pin1 {
          bottom: 16.5%;
          right: 29.525%;

          &::before {
            content: "Boa Nova";
            color: var(--red-300);
            background-color: var(--background-white);
            width: fit-content;
            white-space: nowrap;

            font-size: 1.125rem;
            font-weight: bold;

            padding: 0.25rem 0.5rem;
            border-radius: 1rem;

            top: -15%;
            left: 20%;

            opacity: 0;

            transition: all 0.3s;

            position: absolute;
          }

          &:hover::before {
            opacity: 1;
          }
        }

        &.pin2 {
          top: 14.5%;
          right: 31.5%;

          &::before {
            content: "Campo Formoso";
            color: var(--red-300);
            background-color: var(--background-white);
            width: fit-content;
            white-space: nowrap;

            font-size: 1.125rem;
            font-weight: bold;

            padding: 0.25rem 0.5rem;
            border-radius: 1rem;

            top: -15%;
            left: -20%;

            opacity: 0;

            transition: all 0.3s;

            position: absolute;
          }

          &:hover::before {
            opacity: 1;
          }
        }

        &.pin3 {
          top: 44.5%;
          right: 12%;

          &::before {
            content: "Candeias";
            color: var(--red-300);
            background-color: var(--background-white);
            width: fit-content;
            white-space: nowrap;

            font-size: 1.125rem;
            font-weight: bold;

            padding: 0.25rem 0.5rem;
            border-radius: 1rem;

            top: -15%;
            left: 25%;

            opacity: 0;

            transition: all 0.3s;

            position: absolute;
          }

          &:hover::before {
            opacity: 1;
          }
        }

        &.pin4 {
          bottom: 2%;
          right: 36%;

          &::before {
            content: "Encruzilhada";
            color: var(--red-300);
            background-color: var(--background-white);
            width: fit-content;
            white-space: nowrap;

            font-size: 1.125rem;
            font-weight: bold;

            padding: 0.25rem 0.5rem;
            border-radius: 1rem;

            top: -15%;
            left: 0%;

            opacity: 0;

            transition: all 0.3s;

            position: absolute;
          }

          &:hover::before {
            opacity: 1;
          }
        }

        &.pin5 {
          bottom: 19.25%;
          left: 26%;

          &::before {
            content: "Iuiu";
            color: var(--red-300);
            background-color: var(--background-white);
            width: fit-content;
            white-space: nowrap;

            font-size: 1.125rem;
            font-weight: bold;

            padding: 0.25rem 0.5rem;
            border-radius: 1rem;

            top: -15%;
            left: 60%;

            opacity: 0;

            transition: all 0.3s;

            position: absolute;
          }

          &:hover::before {
            opacity: 1;
          }
        }

        &.pin6 {
          top: 10%;
          left: 26%;

          &::before {
            content: "Mansidão";
            color: var(--red-300);
            background-color: var(--background-white);
            width: fit-content;
            white-space: nowrap;

            font-size: 1.125rem;
            font-weight: bold;

            padding: 0.25rem 0.5rem;
            border-radius: 1rem;

            top: -15%;
            left: 20%;

            opacity: 0;

            transition: all 0.3s;

            position: absolute;
          }

          &:hover::before {
            opacity: 1;
          }
        }

        &.pin7 {
          top: 49%;
          right: 41%;

          &::before {
            content: "Nova Redenção";
            color: var(--red-300);
            background-color: var(--background-white);
            width: fit-content;
            white-space: nowrap;

            font-size: 1.125rem;
            font-weight: bold;

            padding: 0.25rem 0.5rem;
            border-radius: 1rem;

            top: -15%;
            left: -20%;

            opacity: 0;

            transition: all 0.3s;

            position: absolute;
          }

          &:hover::before {
            opacity: 1;
          }
        }

        svg {
          position: absolute;
          cursor: pointer;
          color: var(--red-300);
          transition: all 0.3s;

          &:hover {
            filter: brightness(0.9);
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .wrapper {
    display: block;
    overflow-y: auto;

    padding: 1rem 3.5rem;

    .about {
      margin-bottom: 3rem;
      padding: 0;
    }

    .contentWrapper {
      grid-template-columns: 100%;
      grid-template-rows: 1fr 2rem;
      padding: 0;
      padding-bottom: 5rem;
      gap: 1.5rem;
    }
  }
}

@media (max-width: 768px) {
  .wrapper {
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    scrollbar-width: 0px;
  }
}

@media (max-width: 425px) {
  .wrapper {
    padding: 1rem 2rem;
    max-height: calc(100vh - 4rem);
  }
}

@keyframes opacity-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes opacity-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
