.container {
  margin-top: 1rem; 
  width: 100%; 
  display: flex;
  align-items: center;
  justify-content: center;

  padding-bottom: 4rem; 
}

.navButton {
  border: none; 
  background-color: var(--red-300); 
  height: fit-content; 
  padding: .5rem 1rem; 
  border-radius: 1rem; 

  text-align: center;
  
  font-size: 1.5rem; 
  cursor: pointer;
  
  transition: filter 0.5s;

  & + button { 
    margin-left: 3rem; 
  }

  &:hover {
    filter: brightness(0.9); 
  }

  &:disabled {
    filter: brightness(0.9); 
    
    cursor: not-allowed;
  }
} 