.container {
  animation: opacity-in 2s ease forwards;
  
  &.transition {
    animation: opacity-out 1s ease forwards; 
  }
}

.wrapper {
  max-width: 1350px; 
  padding: 0 2rem 3rem; 
  margin: 2rem auto 0; 
  
  display: flex; 
  flex-direction: column;
  align-items: center;
  justify-content:center;

  .backLink {
    align-self: flex-start;
    height: 3rem;
    width: 3rem;
    opacity: 0.3; 
    position: relative;

    transition: opacity 0.3s; 

    cursor: pointer;

    &::before {
      content: 'Voltar';
      opacity: 0;

      height: 100%; 

      font-size: 2rem; 
      color: black;  
      position: absolute; 
      top: 0;
      
      right: -150%;
      

      transform: translateX(-100%);

      transition: all .3s;
    }

    &:hover {
      opacity: 1;

      &::before {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  h2 {
    font-size: 4rem;
    margin-bottom: 2rem; 
    align-self: flex-start;
    color: var(--red-300); 
  }

  h3 {
    margin-top: 2rem;
    margin-bottom: 1rem; 
    font-size: 2rem; 
  }

  hr {
    height: 3px; 
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    margin-bottom: 2rem;
  }

  p {
    width: 100%; 
    font-size: 1.5rem; 

    text-align: justify;
   

    text-indent: 1.5rem;
    margin: 1rem 0;

    &.center {
      width: fit-content;
      text-align: center;
      margin: 2rem 0; 
      
    }

    &.end {
      width: fit-content;
      align-self: end;

      text-align: end;
    }

    &.oneFourth {
      width: 75%;
    }

    &.threeFourths {
      width: 75%;
      text-align: end;
    }

    &.flex {
      display: flex;
      justify-content: space-between;
    }

    &.almostCenter {
      width: 62.5%;
      margin-left: 37.5%;
    }

    &.almostPastCenter {
      width: 37.5%;
      margin-left: 62.5%;
    }

    a {
      color: var(--red-500); 
      transition: filter .3s;

      &:hover {
        filter: brightness(0.8);
      }
    }

    span {
      font-style: normal;
    }
  }

  i {
    font-size: 1.5rem; 

    text-align: justify;
   

    text-indent: 1.5rem;
    margin-top: 1rem;
  }

  mark {
    background-color: transparent;
    color: var(--red-500);
  }

  img {
    max-width: 60%;
    margin-top: 2rem;
    
    border-radius: 1rem; 

    &.bigger {
      width: 60%; 
    }
  }

  .youtubeVideo {
    margin-top: 2rem;
    border-radius: 1rem; 

    width: 40vw; 
    height: 23vw;
  }
}

@keyframes opacity-in {
  from{
    opacity: 0; 
  }
  to {
    opacity: 1;
  }
}

@keyframes opacity-out {
  from{
    opacity: 1; 
  }
  to {
    opacity: 0;
  }
}

@media (max-width: 1024px) {
  .wrapper {
    h2 {
      font-size: 2.5rem; 
      max-width: 100%;
      overflow: hidden;
    }
  
    .youtubeVideo {
      width: 60vw;
      height: 34.5vw; 
    }
  }
}

@media (max-width: 768px) {
  .wrapper {
    .youtubeVideo {
      width: 80vw;
      height: 46vw; 
    }
  }
}