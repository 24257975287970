.wrapper {
  width: 100vw;
  height: 100vh;
  margin: auto;

  position: relative;
  overflow: hidden;

  filter: blur(2rem);
  animation: blur-in 1.5s ease-out forwards;

  .header {
    height: 100vh;
    width: 100vw;

    padding: 4rem;

    font-size: 10vw;
    line-height: 13vw;

    position: absolute;

    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    
    strong {
      display: block;
      margin-left: 10rem;
    }

    &.transition {
      animation: opacity-out 1.5s ease-out forwards;
      -webkit-animation-timing-function: linear;
    }
  }

  .videoContainer {
    height: 100vh;
    width: 100vw;

    position: absolute;
    bottom: 0;
    left: 0;

    transform: scaleY(0);
    transform-origin: bottom center;

    display: flex;
    align-items: center;
    justify-content: center;

    .video {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100vw;
      height: 100vh;
      transform: translate(-50%, -50%);
    }

    .videoOverlay {
      height: 100vh;
      width: 100vw;
  
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &.transition {
      background-color: var(--black);

      animation: growDiv 1.5s ease-out forwards;
      -webkit-animation-timing-function: linear;
    }
  }
}

@keyframes blur-in {
  to {
    filter: blur(0px);
  }
}

@keyframes opacity-out {
  0% {
    opacity: 1;
  }
  100% {
    top: -100vh;
    opacity: 0;
    display: none;
    visibility: hidden;
  }
}

@keyframes growDiv {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

@media (max-width: 768px) {
  .wrapper {
    .header {
      font-size: 9vw;
      line-height: 11vw;
    }
  }
}

@media (max-width: 425px) {
  .wrapper {
    .header {
      font-size: 7vw;
      line-height: 9vw;
    }

    .videoContainer {
      max-width: 100vw;
    }
  }
}

@media (max-width: 320px) {
  .wrapper {
    .header {
      font-size: 6vw;
      line-height: 8vw;
    }
  }
}

