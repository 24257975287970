* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* variaveis de cor */
:root {
  --background-white: #d6d7d2;
  --black: #000000;
  --red-500: #da2632;
  --red-800: #A41623;
  --red-300: #DF919E; 
}

/* começo da responsividade */
@media (max-width: 1080px) {
  html {
    font-size: 93.75%;
  }
}

@media (max-width: 720px) {
  html {
    font-size: 87.5%;
  }
}

/* estilos globais */
body {
  background-color: var(--background-white);
  font-family: "Darker Grotesque", sans-serif;
}

button {
  font-family: "Darker Grotesque", sans-serif;
}

img {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
