.header {
  max-width: 1600px;
  width: 100%;
  height: 6rem;

  padding: 0 2rem; 
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .Logo {
    width: fit-content;

    display: flex;
    align-items: center;

    border: none;
    background-color: transparent;
    color: var(--black);

    cursor: pointer;

    img {
      height: 6rem;
      width: 6rem;
  
      -webkit-user-drag: none;
      user-select: none;
    }

    h1 {
      height: 100%; 
      line-height: 1.5rem; 
    }
  
    strong {
      display: block;
      margin-left: 1rem;
    }
  }

  nav {
    display: flex;
    align-items: center;

    font-size: 2rem; 

    button {
      margin-left: 1rem;
      padding: 0 .5rem .25rem; 

      border: none; 
      background-color: transparent;

      font-size: 2rem;

      cursor: pointer;
    }

    .about {
      border-radius: 2rem;

      transition: all .3s; 

      &:hover {
        background-color: var(--background-white);
        color: var(--red-300);
        filter: brightness(0.9) 
      }
    }

    .random {
      border-radius: 2rem;
      
      text-align: center;

      transition: all .3s; 

      &:hover {
        background-color: var(--background-white);
        color: var(--red-300);
        filter: brightness(0.9) 
      }
    }

    .menuIcon {
      margin-left: 1rem; 

      cursor: pointer;
    }
  }
}

@media (max-width: 800px) {
  .about {
    display: none;
    visibility: hidden;
  }
}

@media (max-width: 600px) {
  .random {
    display: none;
    visibility: hidden;
  }

  .header {
    justify-content: center;

    .menuIcon {
      position: absolute; 

      right: 1rem; 
    }
  }
}

@media (max-width: 425px) {
  .header {
    height: 4rem; 

    .Logo {

      h1 {
        font-size: 1.5rem; 
        line-height: 1.25rem; 
      }

      img {
        height: 4rem; width: 4rem;
      }
    }
  }
}